import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 2-2-2-2-2 to a 2RM`}</p>
    <p>{`Barbell Rows 2-2-2-2-2 to a 2RM`}</p>
    <p>{`then,`}</p>
    <p>{`500M Row Buy In,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Bench Press (155/95)`}</p>
    <p>{`CTB Pullups`}</p>
    <p>{`500M Row Cash Out.`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Open scores for 18.2 must be submitted no later than
8:00pm tonight!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      